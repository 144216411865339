[class^="icon-"],
[class~=" icon-"],
.icon {
    display: inline-block;
}

.icon,
.icon-20 { width: 20px; height: 20px; }

.icon-xs,
.icon-12 { width: 12px; height: 12px; }

.icon-sm,
.icon-16 { width: 16px; height: 16px; }

.icon-26 { width: 26px; height: 26px; }

.icon-lg,
.icon-40 { width: 40px; height: 40px; }

.icon-xl,
.icon-64 { width: 64px; height: 64px; }