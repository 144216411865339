
// primary
.text-primary {color: var(--color-primary) !important;}
.bg-primary {background-color: var(--color-primary) !important;}

.text-primary-alt {color: var(--color-primary-alt) !important;}
.bg-primary-alt {background-color: var(--color-primary-alt) !important;}

// secondary
.text-secondary {color: var(--color-secondary) !important;}
.bg-secondary {background-color: var(--color-secondary) !important;}

.text-white {color: $white !important;}
.bg-white {background-color: $white !important;}

// Disabled (primary)
.text-disabled {color: var(--color-primary-disabled);}
.bg-disabled {background: var(--color-primary-disabled);}

// Errors / Success
.text-error {color: var(--color-error);}
.bg-error {background: var(--color-bg-error);}
.text-success {color: var(--color-success);}
.bg-success {background: var(--color-success);}
