mat-button-toggle-group[name="voucher-amount"],
mat-button-toggle-group[name="offer-voucher"] {
  width: fit-content;
  display: flex;
  gap: 20px;
  border: none;

  &.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
    flex-wrap: wrap;
  }

  mat-button-toggle {
    border: var(--mat-btn-toggle-border) solid var(--b-color) !important;
    border-radius: 20px;
    display: flex;
    align-items: center;

    &.mat-button-toggle-checked {
      background-color: var(--color-secondary-bg);

      button.mat-button-toggle-button {
        color: var(--color-secondary);
      }
    }

    &.mat-button-toggle-checked,
    &:hover {
      border-width: var(--mat-btn-toggle-hover-border) !important;
      border-color: var(--color-secondary) !important;
    }

    button.mat-button-toggle-button {
      padding: 8px 20px;
      span.mat-button-toggle-label-content {
        padding: 0;
        line-height: 24px;
        font-family: var(--font-default);
        font-size: 16px;
        font-weight: 400;
        display: flex;
        span.span-input {
          display: block;
          margin-right: 5px;
          min-width: 25px;
          height: 15px;
          &.bottom-border {
            border-bottom: 1px dashed;
            border-color: var(--mat-btn-toggle-border);
          }

          &:focus-visible {
            outline: none;
          }
        }
      }
    }
  }
}

mat-button-toggle-group[name="gender-select"] {
  .mat-button-toggle-checked {
    background-color: var(--color-secondary-bg);
  }
}

app-button-toggle.ng-invalid {
  mat-button-toggle-group {
    mat-button-toggle.mat-button-toggle-checked {
      background-color: var(--color-bg-error) !important;
      border-width: var(--mat-btn-toggle-hover-border) !important;
      border-color: var(--color-error) !important;

      button.mat-button-toggle-button {
        color: var(--color-primary) !important;
      }
    }
  }
}

.mat-button-toggle {
  font-family: var(--font-default);
}
