@import '../abstracts';

// Replace "myProject" with project name (not in camel case)

[class^="btn-gift-voucher"],
[class*=" btn-gift-voucher"] {
  @include flex-center();
  min-height: 42px;
  padding: 5px 20px;
  border-radius: var(--b-radius-default);
  font-size: var(--font-size-default);
  font-weight: 500;
  text-decoration: none;
  transition: all .3s ease 0s;
}

.sp-btn {
  border-radius: var(--b-radius-xlg);
}

.btn-gift-voucher-continue {
  color: var(--color-text-light);
  background: var(--continue-btn-bg);

  &:hover {
    background: var(--continue-btn-bg-hover);
  }
  &:focus {
    background: var(--continue-btn-bg);
  }
  &:disabled {
    color: var(--color-primary-disabled);
    background-color: var(--color-primary-disabled-bg);
  }
}

.btn-gift-voucher {
  background: var(--color-secondary);
  color: var(--color-text-light);
  border: 2px solid var(--color-secondary);

  &:hover {
    background: var(--color-secondary-darker);
    border-color: var(--color-secondary-darker);
    color: var(--color-text-light);
  }

  &:disabled,
  &.disabled {
    color: var(--color-text-light);
    background: var(--color-primary-disabled);
    border-color: var(--color-primary-disabled);
  }
}

.btn-gift-voucher-secondary {
  background: var(--color-text-light);
  color: var(--color-primary);
  border: 1px solid var(--b-color);

  &:hover {
    border: 2px solid var(--color-secondary);
    background-color: var(--color-secondary-bg);
  }

  &:disabled,
  &.disabled {
    color: var(--color-primary-disabled);
    border-color: var(--color-text-light);
  }
}

.btn-gift-voucher-white {
  background: var(--color-text-light);
  color: var(--color-primary);
  border: 2px solid var(--color-text-light);

  &:hover {
    border-color: var(--color-secondary);
  }

  &:disabled,
  &.disabled {
    color: var(--color-primary-disabled);
    border-color: var(--color-text-light);
  }
}

.btn-gift-voucher-grey {
  background: var(--color-alt-100);
  color: var(--color-primary);
  border: 2px solid var(--color-alt-100);

  &:hover {
    border-color: var(--color-primary);
  }

  &:disabled {
    color: var(--color-primary-disabled);
    border-color: var(--color-alt-100);
  }
}

.btn-gift-voucher-error {
  background: var(--color-error);
  color: var(--color-text-light);
  border: 2px solid var(--color-error);

  &:hover {
    background: var(--color-error);
    color: var(--color-text-light);
  }

  &:disabled {
    color: var(--color-primary-disabled);
    border-color: var(--color-alt-100);
  }
}

.btn-gift-voucher-success {
  background: var(--color-success);
  color: var(--color-text-light);
  border: 2px solid var(--color-success);

  &:hover {
    background: var(--color-success);
    color: var(--color-text-light);
  }

  &:disabled {
    color: var(--color-primary-disabled);
    border-color: var(--color-alt-100);
  }
}
