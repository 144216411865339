.adyen-checkout__payment-method--selected {
  background: none;
  border: none;
}

.adyen-checkout__button {
  background: var(--color-secondary);
  border-radius: var(--b-radius-default);

  &:hover {
    background: var(--color-secondary-darker);
    box-shadow: none;
  }

  &:disabled {
    background: var(--color-primary-disabled);
  }
}

.adyen-checkout__payment-method__brands {
  .adyen-checkout__payment-method__image__wrapper {
    &, img {
      width: 40px;
      height: 26px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .adyen-checkout__payment-method__header__title {
    width: auto;
    justify-content: center;
    margin-bottom: 10px;

    .adyen-checkout__payment-method__image__wrapper {
      display: none;
      &, img {
        width: calc(40px * .7);
        height: calc(26px * .7);
      }
    }
  }
  .adyen-checkout__payment-method__brands {
    width: auto;
    justify-content: center;
    gap: 5px;
    margin-bottom: 10px;
    .adyen-checkout__payment-method__image__wrapper {
      justify-content: center;
    }
  }
}


.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label:after {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

.adyen-checkout__checkbox__input {
  &:checked:hover,
  &:focus {
    + .adyen-checkout__checkbox__label:after {
      border-color: var(--color-secondary);
      box-shadow: 0 0 0 2px var(--color-secondary-transparent);
    }
  }
}

.adyen-checkout__payment-method__radio--selected {
  background-color: var(--color-secondary);

  &:hover {
    box-shadow: 0 0 0 2px var(--color-secondary-transparent);
  }
}

.adyen-checkout__input--error,
.adyen-checkout__input--invalid {
  border-color: var(--color-error) !important;
}

.adyen-checkout__error-text {
  color: var(--color-error);
}

.adyen-checkout__input:not(.adyen-checkout__input--invalid) {
  border-color:  var(--color-gris-5);
  border-radius: var(--b-radius-default);
}

.adyen-checkout__label--focused {
  .adyen-checkout__label__text {
    color: var(--color-secondary);
  }
}

.adyen-checkout__input--focus,
.adyen-checkout__input--focus:hover,
.adyen-checkout__input:active,
.adyen-checkout__input:active:hover,
.adyen-checkout__input:focus,
.adyen-checkout__input:focus:hover {
  border-color: var(--color-secondary);
  box-shadow: 0 0 0 2px var(--color-secondary-transparent);
}

*[class^='adyen-checkout__']{
  font-family: var(--font-default);
}

.adyen-checkout__label__text {
  font-size: var(--font-size-13);
  color: var(--color-primary);
}

.adyen-checkout__payment-method__name {
  color: var(--color-primary);
  font-family: var(--font-default);
  font-size: var(--font-size-15);
  font-weight: 400;
}

.adyen-checkout-input__inline-validation--valid {
  margin-top: -0.3rem;
}

.adyen-checkout-input__inline-validation--invalid {
  margin-top: -0.2rem;
}

.adyen-checkout__payment-method__brands {
  height: auto;
}

.adyen-checkout__payment-method__details {
  padding: 0;
}

li.adyen-checkout__payment-method--card.adyen-checkout__payment-method--credit.adyen-checkout__payment-method--selected {
  div:not(.adyen-checkout__input-wrapper) {
    position: unset !important;
  }

  .adyen-checkout__card__brands {
    position: absolute;
    top: 1.75rem;
    right: 0;
    height: auto;
    opacity: 1;

    @include media-breakpoint-down(sm) {
      top: 1.25rem;
    }

    .adyen-checkout__card__brands__brand-wrapper {
      width: 40px;
      height: auto;

      img.adyen-checkout__image {
        width: 100%;
        height: auto
      }
    }
  }
}
