.gv-snackbar {
  &.mat-mdc-snack-bar-container {
    max-width: calc(100vw - 48px);
    border-radius: 6px;
    padding-right: 0;

    &.success .mdc-snackbar__surface:before {
      background-color: #1ea97c;
    }

    &.info .mdc-snackbar__surface:before {
      background-color: #696cff;
    }

    &.warning .mdc-snackbar__surface:before {
      background-color: #cc8925;
    }

    &.error .mdc-snackbar__surface:before {
      background-color: var(--color-error);
    }

    &.precheckin-lg {
      width: calc(100vw - 48px);
      margin: 24px;
    }
  }
}
