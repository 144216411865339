// font-families

// font-sizes
.fs-10 {font-size:10px;}
.fs-12 {font-size:12px;}
.fs-14 {font-size:14px;}
.fs-16 {font-size:16px;}
.fs-18 {font-size:18px;}
.fs-20 {font-size:20px;}
.fs-22 {font-size:22px;}
.fs-24 {font-size:24px;}
.fs-34 {font-size:34px;}
.fs-36 {font-size:36px;}
.fs-48 {font-size:48px;}

.fs-small {
  font-size: var(--font-size-sm)
}
.fs-default {
  font-size: var(--font-size-default)
}

// font-weights
.fw-bold {font-weight: bold;}
.fw-medium {font-weight: 500;}
.fw-light {font-weight: 300;}