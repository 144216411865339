.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  min-height: 48px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-width: 2px;
  border-color: var(--color-secondary) !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing,
.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading {
  border-width: 2px;
}

.mat-mdc-form-field-hint-wrapper {
  width: 100%;
}

.mat-mdc-form-field-error {
  font-family: var(--font-default);
  font-size: var(--font-size-sm);
  font-weight: 400;
}

mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align,
.mat-mdc-form-field-subscript-wrapper {
  display: flex;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  position: relative !important;
}
