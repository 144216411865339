@import '/src/styles/abstracts';

/* Application-wide Styles */

html, body { height: 100%; }

body {
  margin: 0;
  padding:0;
  font-family: var(--font-default);
  color: var(--color-primary);
  background-color: var(--bg-default);
}

html.no-scroll {
  body {
    margin: 0 !important;
    width: 100%;
    overflow: hidden;
  }
}

#wrapper {
  font-size: var(--font-size-default);
}

#layout-content:not(.confirmation-screen) {
  width: 100%;
  padding-top: calc(40px + var(--header-height));
  min-height: calc(100vh - var(--footer-height));

  @include media-breakpoint-down(lg) {
    padding-top: calc(40px + var(--header-height-tablet));
    min-height: calc(100vh - var(--footer-height-tablet));
  }

  @include media-breakpoint-down(md) {
    padding-top: 0;
    min-height: auto;
  }
}

#layout-content {
  width: 100%;
  padding-top: calc(40px + var(--header-height));
  min-height: calc(100vh - var(--footer-height));

  @include media-breakpoint-down(lg) {
    min-height: calc(100vh - var(--footer-height-tablet));
  }

  @include media-breakpoint-down(md) {
    padding-top: 0;
    min-height: calc(100vh - var(--footer-height-mobile));
  }
}

#header {
  position: fixed;
  z-index: 100;
  top:0;
  left: 0;
  width: 100%;
  max-width: 100vw;

  @include media-breakpoint-down(md) {
    position: relative;
  }
}

a {
  color: var(--color-link);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

button.no-style {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
}
