@import '/src/styles/abstracts/mixins';

// NON-THEME SPECIFIC VARIABLES GOES HERE

$black: #000;
$white: #fff;

$header-height: 56px;
$header-height-mobile: 144px;
$footer-height: 67px;

:root {
  --header-height: #{$header-height};
  --header-height-mobile: #{$header-height-mobile};
  --footer-height: #{$footer-height};
}